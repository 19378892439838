@import '@array-app/frontend/ui/typography';
@import '@array-app/frontend/ui/color';
@import '@array-app/frontend/ui/style';

body {
	margin: 0;
	padding: 0;
	height: 100%;

	background-color: var(--background-secondary);
}
