body {
	// Utility
	--color-success: #39db80;
	--color-danger: #dc3545;
	--color-caution: #ffcc00;
	--color-information: #3d85e5;

	// Brand
	--color-primary-hs: 171, 100%;
	--color-primary-l: 50%;

	--color-primary: hsl(var(--color-primary-hs), var(--color-primary-l));
	--color-primary-offset: hsla(
		var(--color-primary-hs),
		var(--color-primary-l),
		12%
	);

	// Tint
	--color-primary-tint-15: hsl(
		var(--color-primary-hs),
		calc(var(--color-primary-l) + 10%)
	);
	--color-primary-tint-30: hsl(
		var(--color-primary-hs),
		calc(var(--color-primary-l) + 30%)
	);

	// Shade
	--color-primary-shade-15: hsl(
		var(--color-primary-hs),
		calc(var(--color-primary-l) - 10%)
	);
	--color-primary-shade-30: hsl(
		var(--color-primary-hs),
		calc(var(--color-primary-l) - 30%)
	);

	--color-secondary: #232223;
	--color-tertiary: #fff;

	&[theme='dark'] {
		// Neutrals
		--color-neutral-1: #111318;
		--color-neutral-1a: #1d21293d;
		--color-neutral-2: #1d2129;
		--color-neutral-3: #2a303a;
		--color-neutral-4: #3b3d48;
		--color-neutral-5: #464856;
		--color-neutral-6: #4d5160;
		--color-neutral-8: #5b5d70;

		// Text
		--text-primary: #e5e5e8; // The other themes neutral-4
		--text-secondary: #d1d2d7; // The other themes neutral-6
		--text-tertiary: #9799a5; // The other themes neutral-8
		--text-inverse: #373737; // The other themes primary text

		// Offsets
		--color-success-offset: #06160d;
		--color-danger-offset: #160507;
		--color-caution-offset: #191400;
		--color-information-offset: #060d17;

		// Backgrounds
		--background-inverse: #fff; // The other themes neutral-1
		--background-tertiary-inverse: #ebecee; // The other themes neutral-3
	}

	&[theme='light'],
	&:not(theme) {
		// Neutrals
		--color-neutral-1: #fff;
		--color-neutral-1a: #f3f4f580;
		--color-neutral-2: #f3f4f5;
		--color-neutral-3: #ebecee;
		--color-neutral-4: #e5e5e8;
		--color-neutral-5: #d9dade;
		--color-neutral-6: #d1d2d7;
		--color-neutral-8: #9799a5;

		// Text
		--text-primary: #3b3d48; // The other themes neutral-4
		--text-secondary: #4d5160; // The other themes neutral-6
		--text-tertiary: #5b5d70; // The other themes neutral-8
		--text-inverse: #e4e3e2; // The other themes primary text

		// Offsets
		--color-success-offset: #ebfbf2;
		--color-danger-offset: #fcebec;
		--color-caution-offset: #fffae6;
		--color-information-offset: #ecf3fc;

		// Backgrounds
		--background-inverse: #111318; // The other themes neutral-1
		--background-tertiary-inverse: #2a303a; // The other themes neutral-3
	}

	--color-dark: #3b3d48;
	--color-light: #e5e5e8;

	// Text
	--text-light: var(--color-light);
	--text-dark: var(--color-dark);

	// Background
	--background-primary: var(--color-neutral-1);
	--background-secondary: var(--color-neutral-2);
	--background-tertiary: var(--color-neutral-3);
	--background-dark: var(--color-dark);
	--background-light: var(--color-light);

	// Misc
	--separator-primary: var(--background-primary);
	--separator-secondary: var(--background-secondary);
	--separator-tertiary: var(--background-tertiary);
	--separator-inverse: var(--background-tertiary-inverse);
}
