.cdk-overlay-pane {
	.mat-mdc-menu-panel {
		transform: translateY(calc(var(--spacing) * 2));
		background: var(--background-primary);
		border: 1px solid var(--separator-tertiary);
		box-sizing: border-box;

		.mat-mdc-menu-item {
			--menu-item-text-color: var(--text-primary);

			border-radius: 0;
			display: flex;
			align-items: center;

			&.stackable {
				padding: calc(var(--spacing) * 2) calc(var(--spacing) * 3);
				flex-direction: column;
				height: auto;

				& > * {
					flex: 0 0 auto;
					display: flex;
					margin: 0;
					align-items: center;
					width: 100%;
					flex-wrap: wrap;
					white-space: pre-line;
				}
			}

			svg,
			i {
				--icon-size: 16px;
				margin-right: calc(var(--spacing) * 3);
			}

			.mat-mdc-menu-item-text {
				color: var(--menu-item-text-color);
				font-family: var(--font-family-secondary);
				font-weight: 600;
			}

			&.danger {
				--menu-item-text-color: var(--color-danger);
			}

			&.success {
				--menu-item-text-color: var(--color-success);
			}

			&[disabled] {
				cursor: not-allowed;
			}

			&:hover:not([disabled]) {
				background-color: var(--background-tertiary);
			}
		}

		&.inline-form-menu {
			width: 350px;
			max-width: none;
			min-width: none;

			.mat-mdc-menu-content {
				padding: 0;
			}
		}

		&.filter-menu {
			width: 200px;
			max-width: none;
			min-width: none;

			.mat-mdc-menu-content {
				padding: 0;
			}

			.mat-mdc-checkbox {
				width: 100%;

				.mdc-form-field {
					width: inherit;

					.mdc-label {
						width: inherit;
					}
				}
			}

			.container {
				padding: calc(var(--spacing) * 2);
				max-height: 210px;
				overflow: hidden auto;

				button.link {
					margin: calc(var(--spacing) * 3) 0 calc(var(--spacing) * 2);
					padding: 0 var(--spacing);
				}

				p:not(:first-child) {
					margin-top: calc(var(--spacing) * 3);
				}
			}

			.footer {
				border-top: 1px solid var(--separator-tertiary);
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: calc(var(--spacing) * 3) calc(var(--spacing) * 2);
			}
		}

		&.global-search-menu {
			width: 400px;
			max-width: none;
			min-width: none;

			.mat-mdc-menu-content {
				padding: 0;
			}

			.content-container {
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				width: 100%;
				overflow: hidden auto;
				max-height: 450px;

				& > * {
					padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);

					&:not(:last-child) {
						border-bottom: 1px solid var(--separator-tertiary);
					}
				}
			}

			.content-footer {
				border-top: 1px solid var(--separator-tertiary);
				padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
			}
		}

		&.notification-menu {
			width: 425px;
			max-width: none;
			min-width: none;

			.mat-mdc-menu-content {
				padding: 0;
			}

			.content-container {
				max-height: 450px;
				overflow: hidden auto;
				padding: 0 calc(var(--spacing) * 4);

				app-notification-item:not(:first-of-type) {
					border-top: 1px solid var(--separator-tertiary);
				}
			}

			.content-footer {
				border-top: 1px solid var(--separator-tertiary);
				padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
			}
		}
	}
}
