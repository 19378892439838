mat-chip-list.mat-mdc-chip-list {
	padding: calc(var(--spacing) / 2);
	border: 1px solid var(--input-border-color);
	border-radius: var(--border-radius-box);
	max-width: calc(var(--input-width) + (var(--spacing) * 7));

	.mat-mdc-chip-list-wrapper {
		margin: 0;

		input {
			padding: calc(var(--spacing) * 2);
			border: none;
			outline: none;

			&::placeholder {
				opacity: 1;
				color: var(--color-neutral-6);
				letter-spacing: 0;
			}
		}
	}

	&:focus,
	&:focus-within {
		outline: -webkit-focus-ring-color auto 1px;
	}
}

mat-chip.mat-mdc-chip {
	margin: 0 var(--spacing) var(--spacing) 0;
}

mat-chip-row.mat-mdc-chip-row,
mat-chip.mat-mdc-chip {
	--mdc-chip-elevated-container-color: var(--background-secondary);
	--mdc-chip-label-text-color: var(--text-primary);
	--mdc-chip-with-trailing-icon-trailing-icon-color: var(--text-primary);

	display: inline-flex;
	width: fit-content;
	border-radius: var(--border-radius);
	font-family: var(--font-family-secondary);
	border: 1px solid var(--separator-tertiary);
	font-size: var(--font-size-small);
	color: var(--text-primary);

	button.mat-mdc-chip-remove {
		height: 20px;
		width: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--text-tertiary);
		margin-left: var(--spacing);
		background: transparent;

		i,
		svg {
			--icon-size: 16px;
		}
	}
}

mat-chip[tagChip],
mat-chip-row[tagChip] {
	cursor: pointer;

	span[matchipaction] {
		cursor: pointer;
	}
}
