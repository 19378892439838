$spacing: 4px;
$sides: (top, bottom, left, right); // Leave this variable alone
$axis: (h, v); // Leave this variable alone

@for $i from 0 through 15 {
	.margin-#{$i} {
		margin: $spacing * $i;
	}

	.padding-#{$i} {
		padding: $spacing * $i;
	}

	@each $side in $sides {
		.margin-#{$side}-#{$i} {
			margin-#{$side}: $spacing * $i;
		}

		.padding-#{$side}-#{$i} {
			padding-#{$side}: $spacing * $i;
		}
	}

	@each $a in $axis {
		.margin-#{$a}-#{$i} {
			@if $a == h {
				margin-left: $spacing * $i;
				margin-right: $spacing * $i;
			} @else if $a == v {
				margin-top: $spacing * $i;
				margin-bottom: $spacing * $i;
			}
		}

		.padding-#{$a}-#{$i} {
			@if $a == h {
				padding-left: $spacing * $i;
				padding-right: $spacing * $i;
			} @else if $a == v {
				padding-top: $spacing * $i;
				padding-bottom: $spacing * $i;
			}
		}
	}
}

.hidden {
	display: none;
}

.full-width {
	width: 100%;
	box-sizing: border-box;
}

.half-width {
	width: 50%;
	box-sizing: border-box;
}

.fit-content-width {
	width: fit-content;
}

.clickable {
	cursor: pointer;
}
