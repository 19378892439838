@import '@array-app/frontend/ui/responsive';

@import 'lib/karla';
@import 'lib/montserrat';

body {
	--font-size-xsmall: 10px;
	--font-size-small: 14px;
	--font-size-medium: 16px;
	--font-size-large: 22px;
	--font-size-xlarge: 28px;
	--font-size-xxlarge: 42px;
	--font-family-primary: Montserrat, Arial, Helvetica, sans-serif;
	--font-family-secondary: Karla, Arial, Helvetica, sans-serif;

	--paragraph-indent: 24px;

	font-family: var(--font-family-primary);
	font-weight: 500;
	color: var(--text-primary);
}

p,
li {
	line-height: 1.5;
	font-size: var(--font-size-medium);
	font-family: var(--font-family-secondary);

	&.label,
	&.primary {
		font-family: var(--font-family-primary);
	}
}

h1 {
	font-size: var(--font-size-xxlarge);

	@include mobile {
		font-size: var(--font-size-xlarge);
	}
}

h2 {
	@include mobile {
		font-size: var(--font-size-large);
	}
}

h1,
h2,
h3,
h4,
h5 {
	font-family: var(--font-family-primary);
	line-height: 1.25;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 24px 0 8px 0;
}

p,
li,
ul {
	margin: 0 0 8px 0;
}

h1,
h2,
h3,
h4,
h5,
p,
li,
ul,
span {
	color: var(--text-primary);

	&.paragraph {
		text-align: justify;
		text-indent: var(--paragraph-indent);
	}

	&.xsmall {
		font-size: var(--font-size-xsmall);
	}

	&.small {
		font-size: var(--font-size-small);
	}

	&.medium {
		font-size: var(--font-size-medium);
	}

	&.large {
		font-size: var(--font-size-large);
	}

	&.xlarge {
		font-size: var(--font-size-xlarge);
	}

	&.xxlarge {
		font-size: var(--font-size-xxlarge);
	}
}

// Colors
.text-white {
	color: var(--text-white);
}

.text-black {
	color: var(--text-black);
}

.text-inverse {
	color: var(--text-inverse);
}

.text-primary {
	color: var(--text-primary);
}

.text-secondary {
	color: var(--text-secondary);
}

.text-tertiary {
	color: var(--text-tertiary);
}

.text-danger {
	color: var(--color-danger);
}

.text-information {
	color: var(--color-information);
}

.text-brand {
	color: var(--color-primary);
}

.text-accent {
	color: var(--color-tertiary);
}

// Alignment
.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-underline {
	text-decoration: underline;
}

.text-underline-dotted {
	text-decoration: underline dotted;
}

.text-capitalize {
	text-transform: capitalize;
}

// Weight
.font-bold {
	font-weight: bold;
}

// Line Clamp
@for $i from 0 through 5 {
	.line-clamp-#{$i} {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: $i;
	}
}
