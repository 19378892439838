mat-checkbox.mat-mdc-checkbox {
	--mdc-checkbox-selected-icon-color: var(--color-primary);
	--mdc-checkbox-selected-hover-icon-color: var(--color-primary);
	--mdc-checkbox-selected-focus-icon-color: var(--color-primary);
	--mdc-checkbox-selected-pressed-icon-color: var(--color-primary);

	--mdc-checkbox-unselected-icon-color: var(--separator-tertiary);
	--mdc-checkbox-unselected-hover-icon-color: var(--separator-tertiary);
	--mdc-checkbox-unselected-focus-icon-color: var(--separator-tertiary);
	--mdc-checkbox-unselected-pressed-icon-color: var(--separator-tertiary);

	.mdc-checkbox__checkmark {
		--icon-size: auto;
	}

	.mdc-label {
		font-family: var(--font-family-secondary);
		color: var(--text-primary);
	}

	.mdc-checkbox__ripple {
		display: none !important;
	}
}
