ngx-ui-loader {
	.ngx-progress-bar,
	.ngx-loading-text,
	.ngx-foreground-spinner {
		color: var(--color-primary) !important;
		opacity: 1 !important;
	}

	.ngx-overlay.loading-foreground {
		background: var(--background-secondary) !important;
		opacity: 0.8;
	}
}
