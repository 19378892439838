.cdk-overlay-container {
	.cdk-global-overlay-wrapper {
		.cdk-overlay-pane {
			&:not(.full-screen) {
				--dialog-size: 450px;

				mat-dialog-container.mat-mdc-dialog-container {
					border-radius: var(--border-radius-box);
					max-height: 80vh;
					min-height: calc(var(--dialog-size) / 1.33);
					width: var(--dialog-size);
				}
			}

			mat-dialog-container.mat-mdc-dialog-container {
				--mdc-dialog-container-color: var(--background-secondary);
				--mdc-dialog-subhead-color: var(--text-primary);

				display: flex;
				padding: 0;

				& > * {
					width: 100%;
					display: flex;
					flex-direction: column;

					.mat-mdc-dialog-title {
						font-weight: bold;
						font-family: var(--font-family-primary);
						border-bottom: 1px solid var(--separator-tertiary);
						font-size: var(--font-size-large);

						&::before {
							display: none;
						}
					}

					.mat-mdc-dialog-title,
					.mat-mdc-dialog-actions {
						margin: 0;
						flex: 0 0 auto;
						box-sizing: border-box;
						padding: calc(var(--spacing) * 3)
							calc(var(--spacing) * 4);
					}

					.mat-mdc-dialog-content {
						margin: 0;
						flex: 1 1 auto;
						max-height: unset;
						box-sizing: border-box;
						font-family: var(--font-family-secondary);
						padding: calc(var(--spacing) * 3)
							calc(var(--spacing) * 4);
					}

					.mat-mdc-dialog-actions {
						gap: 8px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						border-top: 1px solid var(--separator-tertiary);
					}
				}
			}

			&.small {
				--dialog-size: 300px;
			}

			&.large {
				--dialog-size: 700px;
			}

			&.notification {
				min-height: unset;
			}

			&.full-screen {
				max-height: unset !important;
				max-width: unset !important;

				mat-dialog-container.mat-mdc-dialog-container {
					height: 100vh;
					width: 100vw;
					border-radius: 0;
				}
			}
		}
	}
}
