mat-slide-toggle.mat-mdc-slide-toggle {
	height: auto;

	.mdc-form-field {
		align-items: baseline;
		gap: calc(var(--spacing) * 2);
	}

	.mdc-switch--selected {
		.mdc-switch__handle::after {
			background: var(--color-primary) !important;
		}
	}

	.mdc-switch--unselected {
		.mdc-switch__handle::after {
			background: var(--color-neutral-5) !important;
		}
	}

	.mdc-switch {
		&__track {
			&::before,
			&::after {
				background: var(--background-tertiary) !important;
			}
		}

		&__ripple,
		&__icons {
			display: none;
			visibility: none;
		}
	}

	// &.mat-disabled {
	// 	opacity: 0.5;
	// 	cursor: not-allowed;
	// 	user-select: none;

	// 	.mat-mdc-slide-toggle-label,
	// 	.mat-mdc-slide-toggle-thumb-container {
	// 		cursor: not-allowed;
	// 	}
	// }

	// &.mat-checked {
	// 	.mat-mdc-slide-toggle-bar {
	// 		.mat-mdc-slide-toggle-thumb-container {
	// 			.mat-mdc-slide-toggle-thumb {
	// 				background: var(--color-primary);
	// 			}
	// 		}
	// 	}
	// }

	// .mat-mdc-slide-toggle-bar {
	// 	margin-top: 2px;
	// 	background: var(--background-tertiary) !important;

	// 	.mat-mdc-slide-toggle-thumb-container {
	// 		.mat-mdc-slide-toggle-thumb {
	// 			background: white;
	// 			border: 1px solid var(--separator-tertiary);
	// 			box-shadow: none;
	// 			box-sizing: border-box;
	// 		}

	// 		.mat-ripple-element {
	// 			background: var(--color-primary);
	// 		}
	// 	}
	// }

	// .mat-mdc-slide-toggle-label {
	// 	align-items: flex-start;
	// }

	// .mat-mdc-slide-toggle-content {
	// 	h4 {
	// 		margin: 0 0 var(--spacing) 0;
	// 		color: var(--text-secondary);
	// 		font-family: var(--font-family-primary);
	// 	}

	// 	p {
	// 		color: var(--text-tertiary);
	// 		font-family: var(--font-family-secondary);
	// 		font-size: var(--font-size-small);
	// 		margin: 0;
	// 		white-space: normal;
	// 	}
	// }
}
