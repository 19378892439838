table {
	width: 100%;
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;

	tr {
		column-gap: calc(var(--spacing) * 4);

		td,
		th {
			border-collapse: collapse;
			text-align: left;
		}

		& > div,
		& > button,
		& > a {
			display: flex;
			border-radius: 0;
			border: none;
			padding: var(--table-row-padding, calc(var(--spacing) * 4));
			width: 100%;
			text-decoration: none !important;
			font-size: unset;
			color: unset;
			text-align: unset;

			& > * {
				&:first-child {
					padding-left: calc(var(--spacing) * 2);
				}

				&:last-child {
					padding-right: calc(var(--spacing) * 2);
				}
			}
		}

		& > button:not([disabled]),
		& > a:not([disabled]) {
			cursor: pointer;

			&:hover,
			&:focus-visible {
				background-color: var(--background-secondary);
				outline: none;
			}
		}
	}

	thead th {
		padding: calc(var(--spacing) * 2) calc(var(--spacing) * 6);
	}

	tbody td {
		padding: calc(var(--spacing) * 4) calc(var(--spacing) * 6);
	}

	.resource-item-alias,
	app-resource-list-item {
		tbody td {
			padding: calc(var(--spacing) * 4) calc(var(--spacing) * 2);
		}

		td,
		th {
			cursor: pointer;
		}

		&:not(:last-child) {
			tr {
				border-bottom: 1px solid var(--separator-secondary);
			}
		}
	}
}
