.input-container {
	--mdc-icon-button-state-layer-size: 42px;
	--mdc-icon-button-icon-size: 20px;

	.date-container {
		display: flex;
		align-items: center;
		position: relative;
		max-width: calc(var(--input-width) / 2);
	}

	.date-range-container {
		display: flex;
		align-items: center;
		position: relative;

		box-sizing: border-box;
		background: var(--input-background-color);

		max-width: calc(var(--input-width) / 2);
		padding: var(--input-padding);
		border-radius: var(--input-border-radius);
		border: 1px solid var(--input-border-color);

		font-weight: 500;
		font-family: var(--font-family-secondary);
		color: var(--text-primary);
		font-size: var(--font-size-small);

		.mat-mdc-date-range-input-separator {
			color: var(--text-primary);
			opacity: 1;

			&.mat-mdc-date-range-input-separator-hidden {
				color: var(--color-neutral-6);
			}
		}

		.mat-mdc-date-range-input-container {
			input {
				border-radius: 0;
			}
		}

		.mat-mdc-date-range-input-start-wrapper,
		.mat-mdc-date-range-input-end-wrapper {
			flex: 0 0 auto;

			.mat-mdc-date-range-input-mirror {
				margin: 0;
			}
		}

		.mat-mdc-date-range-input-end-wrapper {
			flex: 1 1 auto;
		}

		mat-datepicker-toggle {
			transform: translateX(calc(100% + calc(var(--spacing) * 2)));
		}
	}

	mat-datepicker-toggle {
		position: absolute;
		right: var(--spacing);
		top: 0;
		bottom: auto;
		left: auto;

		button.mat-mdc-icon-button {
			height: 42px;
			width: 42px;
			padding: 10px;
			border: 1px solid var(--separator-tertiary);
			display: flex;
			align-items: center;
			justify-content: center;

			i,
			svg {
				color: var(--text-secondary);
			}

			span {
				margin: 0;
			}
		}
	}
}
