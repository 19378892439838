// Regular
@font-face {
	font-family: Montserrat;
	font-weight: 400;
	src: url('../assets/fonts/montserrat/Montserrat-Regular.ttf')
		format('truetype');
	font-display: swap;
}

@font-face {
	font-family: Montserrat;
	font-weight: 400;
	font-style: italic;
	src: url('../assets/fonts/montserrat/Montserrat-Italic.ttf')
		format('truetype');
	font-display: swap;
}

// Bold
@font-face {
	font-family: Montserrat;
	font-weight: 700;
	src: url('../assets/fonts/montserrat/Montserrat-Bold.ttf')
		format('truetype');
	font-display: swap;
}

@font-face {
	font-family: Montserrat;
	font-weight: 700;
	font-style: italic;
	src: url('../assets/fonts/montserrat/Montserrat-BoldItalic.ttf')
		format('truetype');
	font-display: swap;
}
