color-picker {
	.color-picker {
		background: var(--background-primary);
		border-radius: var(--border-radius-box);
		border: 1px solid var(--separator-tertiary);
		font-family: var(--font-family-secondary) !important;
		color: var(--text-primary) !important;

		.saturation-lightness {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			border-bottom: 1px solid var(--separator-tertiary);
		}

		.cursor {
			border-color: var(--background-dark);
		}

		.arrow {
			border-color: rgba(0, 0, 0, 0) var(--separator-tertiary)
				rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
		}

		.hue-alpha {
			.selected-color {
				border: 1px solid var(--separator-tertiary);
				border-radius: var(--border-radius-box);
			}

			.hue {
				border: 1px solid var(--separator-tertiary);
				border-radius: var(--border-radius);
				box-sizing: content-box;
				margin-bottom: 12px;
			}
		}

		.box {
			div {
				color: var(--text-tertiary) !important;
			}

			input {
				color: var(--text-primary) !important;
				font-size: var(--font-size-small) !important;
				padding: calc(var(--spacing) * 3) !important;
				border-radius: var(--border-radius-box) !important;
				border: 1px solid var(--separator-tertiary) !important;
			}
		}
	}
}
