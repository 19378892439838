.cdk-overlay-container {
	.cdk-overlay-pane {
		mat-snack-bar-container.mat-mdc-snack-bar-container {
			background: var(--background-inverse);
			border-radius: var(--border-radius-box);
			border: 1px solid var(--separator-tertiary);

			.mat-mdc-snack-bar-label {
				color: var(--text-inverse);
			}

			.mdc-snackbar__surface {
				background: var(--background-inverse);

				simple-snack-bar.mat-mdc-simple-snack-bar {
					font-family: var(--font-family-secondary);

					.mat-mdc-snack-bar-actions {
						button {
							background: transparent;
							font-family: var(--font-family-secondary);

							&:focus,
							&:hover {
								background: var(--background-tertiary-inverse);
							}

							.mdc-button__label {
								color: var(--text-inverse);
							}
						}
					}
				}
			}
		}
	}
}
