.cdk-overlay-container {
	.mat-mdc-tooltip-panel {
		mat-tooltip-component {
			.mat-mdc-tooltip {
				background: var(--background-inverse);
				border-radius: var(--border-radius-box);
				padding: calc(var(--spacing) * 2) calc(var(--spacing) * 4);
				white-space: pre-line;

				.mdc-tooltip__surface {
					font-family: var(--font-family-secondary);
					color: var(--text-inverse);
					font-size: var(--font-size-small);
					background: transparent;
					padding: 0;
					min-height: 0;
					height: auto;
				}
			}
		}
	}
}

span.mat-mdc-tooltip-trigger {
	cursor: pointer;

	i,
	svg {
		--icon-size: 16px;
	}
}
