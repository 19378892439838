body {
	--spacing: 4px;
	--border-radius: 9999px;
	--border-radius-box: calc(var(--spacing) * 2);
	--input-width: 488px;
	--nav-header-height: 90px;
	--nav-page-padding: calc(var(--spacing) * 4);

	/**
	 * Shadows
	 */
	--box-shadow: rgba(0, 0, 0, 0.24) 0 0 8px;
	--box-shadow-from-left: rgba(17, 17, 26, 0.05) 3px 3px 3px 0;
	--box-shadow-from-top: rgba(17, 17, 26, 0.05) 0 3px 3px 0;
	--box-shadow-light: rgba(17, 17, 26, 0.05) 0 0 0,
		rgba(17, 17, 26, 0.1) 0 0 3px;
	&[theme='dark'] {
		--box-shadow-from-left: rgba(0, 0, 0, 0.05) 3px 3px 3px 0;
		--box-shadow-from-top: rgba(0, 0, 0, 0.05) 0 3px 3px 0;
		--box-shadow-light: rgba(0, 0, 0, 0.05) 0 0 0,
			rgba(0, 0, 0, 0.5) 0 0 3px;
	}
}
