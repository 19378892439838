mat-datepicker-content.mat-datepicker-content {
	background: var(--background-primary);
	border: 1px solid var(--separator-tertiary);
	border-radius: var(--border-radius-box);
	overflow: hidden;

	mat-calendar.mat-calendar {
		--button-hover-background-color: var(--color-primary-offset);

		background: var(--background-primary);
		font-family: var(--font-family-secondary);
		min-width: 360px;
		height: auto;

		mat-calendar-header {
			.mat-calendar-header {
				button {
					font-family: var(--font-family-primary);
					font-weight: bold;

					.mat-calendar-arrow {
						fill: var(--text-primary);
					}

					span {
						margin: 0;
					}
				}
			}
		}

		.mat-calendar-body-label {
			font-family: var(--font-family-primary);
			font-weight: bold;
		}

		tr {
			td.mat-calendar-body-cell-container {
				&:first-of-type {
					.mat-calendar-body-cell {
						&.mat-calendar-body-in-range {
							border-top-left-radius: var(--border-radius-box);
							border-bottom-left-radius: var(--border-radius-box);

							.mat-calendar-body-cell-content {
								border-top-left-radius: var(
									--border-radius-box
								);
								border-bottom-left-radius: var(
									--border-radius-box
								);
							}

							&::before,
							&::after {
								border-top-left-radius: var(
									--border-radius-box
								);
								border-bottom-left-radius: var(
									--border-radius-box
								);
							}
						}
					}
				}

				&:last-of-type {
					.mat-calendar-body-cell {
						&.mat-calendar-body-in-range {
							border-top-right-radius: var(--border-radius-box);
							border-bottom-right-radius: var(
								--border-radius-box
							);

							.mat-calendar-body-cell-content {
								border-top-right-radius: var(
									--border-radius-box
								);
								border-bottom-right-radius: var(
									--border-radius-box
								);
							}

							&::before,
							&::after {
								border-top-right-radius: var(
									--border-radius-box
								);
								border-bottom-right-radius: var(
									--border-radius-box
								);
							}
						}
					}
				}
			}
		}

		.mat-calendar-body-cell {
			border-radius: var(--border-radius-box);

			&:hover:not(.mat-calendar-body-active) {
				.mat-calendar-body-cell-content {
					background: var(--background-tertiary) !important;
				}
			}

			&.mat-calendar-body-in-range {
				border-radius: 0;

				.mat-calendar-body-cell-content {
					border-radius: 0;
				}

				&.mat-calendar-body-range-start {
					border-top-left-radius: var(--border-radius-box);
					border-bottom-left-radius: var(--border-radius-box);

					.mat-calendar-body-cell-content {
						border-top-left-radius: var(--border-radius-box);
						border-bottom-left-radius: var(--border-radius-box);
					}

					&::before,
					&::after {
						border-top-left-radius: var(--border-radius-box);
						border-bottom-left-radius: var(--border-radius-box);
					}
				}

				&.mat-calendar-body-range-end {
					border-top-right-radius: var(--border-radius-box);
					border-bottom-right-radius: var(--border-radius-box);

					.mat-calendar-body-cell-content {
						border-top-right-radius: var(--border-radius-box);
						border-bottom-right-radius: var(--border-radius-box);
					}

					&::before,
					&::after {
						border-top-right-radius: var(--border-radius-box);
						border-bottom-right-radius: var(--border-radius-box);
					}
				}
			}

			&:not(.mat-calendar-body-in-range) {
				border-radius: var(--border-radius-box);

				.mat-calendar-body-cell-content {
					border-radius: var(--border-radius-box);
				}
			}

			.mat-calendar-body-cell-content {
				background: none !important;
				margin: 0;
				padding: 0;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				height: 100%;
				width: 100%;
			}
		}

		.mat-calendar-body-cell,
		.mat-calendar-body-cell-content,
		.mat-calendar-body-label,
		button.mdc-icon-button,
		button.mat-icon-button {
			color: var(--text-primary) !important;

			&.mat-calendar-body-disabled,
			&[disabled='true'] {
				opacity: 0.33;
				pointer-events: none;
			}
		}

		.mat-calendar-table-header-divider::after {
			background: var(--separator-tertiary);
		}

		button.mdc-icon-button,
		button.mat-icon-button {
			border: 1px solid var(--separator-tertiary);
			margin-left: var(--spacing);

			&::after {
				margin: 14px;
			}
		}

		button {
			.mat-calendar-body-selected {
				background: var(--color-primary-offset);
				border: 1px solid var(--color-primary);
			}

			&.mat-calendar-body-in-range::before {
				background: var(--color-primary-offset);
				height: 100%;
				width: 100%;
				top: 0;
			}

			&.mat-calendar-body-in-preview {
				border: 1px dashed var(--color-primary-offset);
			}

			.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
				border: 1px solid var(--color-neutral-4);
			}

			&.mat-calendar-body-active {
				background: var(--color-primary-offset);
			}

			&:hover:not([disabled]):not(.mat-calendar-body-active) {
				background: var(--background-tertiary);
			}
		}
	}

	.mat-calendar-next-button,
	.mat-calendar-previous-button {
		height: 42px;
		width: 42px;
		padding: 10px;
	}

	.mat-datepicker-close-button {
		font-family: var(--font-family-secondary);
		background: var(--color-primary);
		border-radius: var(--border-radius-box);

		.mat-button-wrapper {
			color: var(--text-dark);
		}
	}
}

.cdk-keyboard-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	background: none;
}
