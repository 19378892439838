// Regular
@font-face {
	font-family: Karla;
	src: url('../assets/fonts/karla/Karla-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: Karla;
	font-style: italic;
	src: url('../assets/fonts/karla/Karla-Italic.ttf') format('truetype');
	font-display: swap;
}
