.cdk-overlay-container {
	.mat-mdc-autocomplete-panel {
		transform: translateY(calc(var(--spacing) * 2));
		background: var(--background-primary);
		border: 1px solid var(--separator-tertiary);
		border-radius: 4px;

		mat-option {
			&.mat-mdc-option {
				border-radius: 0;
				color: var(--text-primary);
				font-family: var(--font-family-secondary);
				font-weight: 600;
				display: flex;
				align-items: center;

				&.mat-mdc-option-disabled {
					cursor: not-allowed;
					opacity: 0.25;
				}

				&:hover:not(.mat-mdc-option-disabled) {
					background-color: var(--background-tertiary);
				}

				.mat-mdc-option-text {
					font-size: var(--font-size-small);
					margin: 0;
					display: flex;
					align-items: center;
				}
			}
		}
	}
}
