mat-select {
	&.mat-mdc-select {
		--select-background-color: transparent;
		--select-border-color: var(--separator-tertiary);
		--select-hover-background-color: var(--background-tertiary);
		--select-text-color: var(--text-primary);
		--select-padding: calc(var(--spacing) * 2) calc(var(--spacing) * 4);
		--select-border-radius: var(--border-radius-box);

		display: flex;

		&.mat-mdc-select-disabled {
			opacity: 0.5;
			cursor: not-allowed;
			user-select: none;
		}

		.mat-mdc-select-trigger {
			font-weight: 600;
			height: auto;
			padding: var(--select-padding);
			border-radius: var(--select-border-radius);
			font-family: var(--font-family-secondary);
			background-color: var(--select-background-color);
			color: var(--select-text-color);
			border: 1px solid var(--select-border-color);
			box-sizing: content-box;

			.mat-mdc-select-arrow {
				visibility: hidden;
				width: 16px;
			}
		}

		.mat-mdc-select-value {
			font-size: var(--font-size-small);
		}

		&.secondary {
			--select-background-color: var(--background-primary);
			--select-hover-background-color: var(--background-tertiary);
			--select-border-color: var(--separator-tertiary);
		}
	}
}

.cdk-overlay-container {
	.mat-mdc-select-panel {
		background: var(--background-primary);
		border: 1px solid var(--separator-tertiary);
		padding: 8px 0;
		min-width: unset !important;

		mat-option {
			&.mat-mdc-option {
				--mat-option-selected-state-label-text-color: var(
					--text-primary
				);

				border-radius: 0;
				font-family: var(--font-family-secondary);
				font-weight: 600;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 8px;

				mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
					color: var(--color-success);
					height: 6px;
					width: 13px;
				}

				&[disabled] {
					cursor: not-allowed;
				}

				&.mat-mdc-selected {
					cursor: default;
				}

				&:hover:not([disabled]):not(.mat-mdc-selected) {
					background-color: var(--background-tertiary);
				}

				.mdc-list-item {
					&__primary-text {
						font-size: var(--font-size-small);
						margin: 0;
						display: flex;
						align-items: center;
					}
				}
			}
		}
	}
}
