// Dependant on `_input.scss`

quill-editor {
	font-family: var(--font-family-secondary);
	// spacing comes from the usual padding applied to the inputs. 4px comes from border width.
	max-width: calc(var(--input-width) + (var(--spacing) * 8) + 4px);

	.ql-snow {
		border-color: var(--input-border-color) !important;
		border-width: 1px !important;

		&.ql-toolbar {
			border-top-right-radius: var(--input-border-radius);
			border-top-left-radius: var(--input-border-radius);

			.ql-formats {
				button {
					--ql-button-size: 24px;
					--ql-button-color: var(--text-tertiary);

					display: flex;
					align-items: center;
					justify-content: center;
					height: var(--ql-button-size);
					width: var(--ql-button-size);
					padding: var(--spacing);

					&.ql-active {
						--ql-button-color: var(--color-primary);
					}

					svg {
						.ql-stroke {
							stroke: var(--ql-button-color);
						}
						.ql-fill {
							fill: var(--ql-button-color);
						}
					}

					&:not(:first-child) {
						margin-left: var(--spacing);
					}
				}
			}
		}

		&.ql-container {
			border-bottom-right-radius: var(--input-border-radius);
			border-bottom-left-radius: var(--input-border-radius);

			.ql-editor {
				min-height: var(--textarea-min-height);
				line-height: 1.75;

				&.ql-blank {
					&::before {
						opacity: 1;
						color: var(--color-neutral-6);
						letter-spacing: 0;
						font-style: normal;
					}
				}
			}

			.ql-tooltip {
				font-family: var(--font-family-secondary);
				font-size: var(--font-size-small);
				background: var(--background-inverse);
				color: var(--text-inverse);
				transform: translateX(25%);
				padding: calc(var(--spacing) * 2) calc(var(--spacing) * 4);
				border: none;
				box-shadow: none;
				border-radius: var(--border-radius-box);

				input {
					font-family: var(--font-family-secondary);
					color: var(--text-inverse);
					border-width: 2px;
					padding: var(--spacing);
					border-color: var(--input-border-color-inverse);
					border-radius: calc(var(--input-border-radius) / 2);
				}

				.ql-action,
				.ql-remove,
				.ql-preview {
					font-style: normal;
					font-size: var(--font-size-small);
					color: var(--anchor-text-color);
					position: relative;
					font-family: var(--font-family-secondary);
					width: fit-content;

					&::after {
						border-color: var(--separator-inverse);
					}
				}

				.ql-action,
				&:not(.ql-editing) .ql-remove {
					display: inline-block;
				}
			}
		}
	}

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
		user-select: none;

		.ql-snow.ql-toolbar,
		.ql-snow.ql-container {
			pointer-events: none;
		}
	}
}

quill-view-html {
	.ql-editor {
		padding: 0;
		font-size: var(--font-size-medium);
		font-family: var(--font-family-secondary);
		color: var(--text-primary);

		p {
			line-height: 1.5;
		}
	}
}

.ql-snow {
	a {
		font-size: unset;
		display: unset;
		color: var(--anchor-text-color);
	}

	blockquote {
		border-color: var(--separator-tertiary) !important;
	}

	.ql-syntax {
		background-color: var(--color-dark) !important;
	}

	ul,
	li,
	ol,
	ul li,
	ol li {
		padding-left: calc(var(--spacing) * 2) !important;
	}

	// indent
	@for $i from 1 through 10 {
		.ql-indent-#{$i} {
			padding-left: calc(var(--spacing) * (2 * ($i + 1))) !important;
		}
	}
}
