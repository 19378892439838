body {
	--input-border-color: var(--separator-tertiary);
	--input-border-color-inverse: var(--separator-inverse);
	--input-background-color: transparent;
	--input-padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
	--input-border-radius: var(--border-radius-box);

	--textarea-min-height: 200px;
}

input,
textarea {
	box-sizing: border-box;
	max-width: var(--input-width);
	background: var(--input-background-color);

	padding: var(--input-padding);
	border-radius: var(--input-border-radius);
	border: 1px solid var(--input-border-color);

	font-weight: 500;
	font-family: var(--font-family-secondary);
	color: var(--text-primary);
	font-size: var(--font-size-small);

	&.small,
	&[type='number'] {
		width: calc(var(--input-width) / 3);
	}

	&.full {
		width: 100%;
	}

	&::placeholder {
		opacity: 1;
		color: var(--color-neutral-6);
		letter-spacing: 0;
	}

	&[type='password'] {
		letter-spacing: var(--spacing);
	}

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
		user-select: none;
	}
}

textarea {
	resize: none;
	min-height: var(--textarea-min-height);
	line-height: 1.75;
}
