mat-button-toggle-group.mat-button-toggle-group {
	border: 1px solid var(--separator-tertiary);
	border-radius: var(--border-radius-box);

	mat-button-toggle.mat-button-toggle {
		--button-toggle-padding: calc(var(--spacing) * 2)
			calc(var(--spacing) * 3);

		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--background-primary);

		& + .mat-button-toggle {
			border-left: 2px solid var(--separator-tertiary);
		}

		&.mat-button-toggle-checked {
			background: var(--background-tertiary);

			button {
				.mat-button-toggle-label-content {
					i,
					svg {
						color: var(--text-primary);
					}
				}
			}
		}

		span {
			margin: 0;
		}

		button {
			border-radius: 0;

			.mat-button-toggle-label-content {
				padding: var(--button-toggle-padding);
				line-height: 0;

				i,
				svg {
					--icon-size: 18px;

					color: var(--text-tertiary);
				}
			}
		}

		&:first-of-type {
			--button-toggle-padding: calc(var(--spacing) * 2)
				calc(var(--spacing) * 3) calc(var(--spacing) * 2)
				calc(var(--spacing) * 4);
		}

		&:last-of-type {
			--button-toggle-padding: calc(var(--spacing) * 2)
				calc(var(--spacing) * 4) calc(var(--spacing) * 2)
				calc(var(--spacing) * 3);
		}
	}
}
