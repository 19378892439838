form,
.display-form {
	&.ng-submitted {
		.ng-invalid app-error-text {
			display: block;
		}

		input:not(:focus),
		textarea:not(:focus),
		app-hex-color-input:not(:focus) {
			&.ng-invalid {
				--input-border-color: var(--color-danger);
			}
		}

		app-image-upload:not(:focus).ng-invalid {
			--image-upload-border-color: var(--color-danger);
		}

		app-card.ng-invalid {
			--card-border-color: var(--color-danger);
		}
	}

	.input-container,
	app-card {
		app-error-text {
			display: none;
		}
	}

	.input-container {
		display: flex;
		flex-direction: column;
		margin-bottom: calc(var(--spacing) * 6);
		position: relative;

		app-error-text {
			position: absolute;
			top: 100%;
			left: calc(var(--spacing) * 2);
			right: auto;
			bottom: auto;
		}

		label {
			font-size: var(--font-size-small);
			color: var(--text-secondary);
			margin-bottom: var(--spacing);
			font-weight: 600;
			padding: 0 calc(var(--spacing) * 2);
			position: relative;
			max-width: var(--input-width);
			box-sizing: border-box;

			.state {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				left: auto;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: var(--spacing);

				span {
					display: contents;
				}

				.danger-text {
					font-size: var(--font-size-small);
					color: var(--color-danger);
					margin: 0;
				}

				.icon {
					--icon-size: 16px;

					&.success {
						color: var(--color-success);
					}

					&.danger {
						color: var(--color-danger);
					}
				}
			}
		}

		app-select-wrapper {
			max-width: calc(var(--input-width) / 2);

			&.small {
				width: 100px;
			}

			&:not(.small) {
				mat-select {
					--select-border-radius: var(--border-radius-box);
				}
			}
		}

		mat-slide-toggle {
			max-width: calc(var(--input-width) * 0.75);
		}

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	app-card {
		margin-bottom: calc(var(--spacing) * 2);
	}
}
