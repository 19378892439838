body {
	--anchor-text-color: var(--color-information);
	--button-background-color: transparent;
	--button-border-color: var(--separator-tertiary);
	--button-hover-background-color: var(--background-tertiary);
	--button-text-color: var(--text-primary);
	--button-cursor: pointer;
	--button-padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
}

button {
	cursor: var(--button-cursor);
	padding: var(--button-padding);
	border-radius: var(--border-radius-box);
	font-family: var(--font-family-secondary);
	font-weight: bold;
	background-color: var(--button-background-color);
	color: var(--button-text-color);
	border: 1px solid var(--button-border-color);
	font-size: var(--font-size-small);
	position: relative;
	overflow: hidden;

	&.primary {
		--button-text-color: var(--text-dark);
		--button-background-color: var(--color-primary);
		--button-hover-background-color: var(--color-primary-tint-30);
		--button-border-color: transparent;

		app-loading-spinner {
			--loading-spinner-color: var(--text-dark);
		}
	}

	&.secondary {
		--button-background-color: var(--background-primary);
		--button-hover-background-color: var(--background-tertiary);
		--button-border-color: var(--separator-tertiary);
	}

	&.danger {
		--button-background-color: var(--background-primary);
		--button-hover-background-color: var(--color-danger-offset);
		--button-border-color: var(--color-danger);
		--button-text-color: var(--color-danger);
	}

	&.tertiary {
		--button-background-color: var(--background-secondary);
		--button-hover-background-color: var(--background-tertiary);
		--button-border-color: var(--separator-tertiary);
	}

	&.transparent {
		--button-background-color: transparent;
		--button-hover-background-color: var(--background-tertiary);
		--button-border-color: transparent;
	}

	&.nav {
		--button-border-color: transparent;
		--button-hover-background-color: transparent;
		--button-padding: 0 calc(var(--spacing) * 5);
		--button-text-color: var(--color-neutral-8);

		border: none;
		border-left: 3px solid transparent;

		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 42px;
		border-radius: 0;
		gap: calc(var(--spacing) * 3);
		box-sizing: border-box;

		i,
		svg {
			--icon-size: 18px;

			flex: 0 0 auto;
		}

		p {
			font-size: var(--font-size-small);
			color: var(--button-text-color);
		}

		&.active {
			--button-text-color: var(--color-primary);
			--button-background-color: var(--color-primary-offset);
			border-left: 3px solid var(--color-primary);
		}

		&:hover:not(.active),
		&:focus-visible:not(.active) {
			--button-text-color: var(--text-primary);
		}
	}

	&.subnav {
		--button-padding: calc(var(--spacing) * 3) calc(var(--spacing) * 3);
		--button-background-color: var(--background-secondary);

		display: flex;
		align-items: center;
		justify-content: flex-start;
		opacity: 0.8;

		font-size: var(--font-size-small);
		color: var(--button-text-color);

		i,
		svg {
			--icon-size: 18px;
			margin-right: calc(var(--spacing) * 2);
		}

		&.active {
			--button-background-color: var(--background-primary);
			opacity: 1;
		}

		&:hover:not(.active) {
			--button-text-color: var(--text-primary);
			opacity: 1;
		}

		&:focus-visible {
			outline: var(--color-light) solid 2px;
		}
	}

	&:hover:not([disabled]):not(.active) {
		background: var(--button-hover-background-color);
	}

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
		user-select: none;
	}

	&.small {
		--button-padding: var(--spacing) calc(var(--spacing) * 2);
	}

	&.icon {
		--button-padding: calc(var(--spacing) * 2) calc(var(--spacing) * 4)
			calc(var(--spacing) * 2) calc(var(--spacing) * 3);

		display: flex;
		align-items: center;
		justify-content: center;

		svg,
		i {
			--icon-size: 16px;
			margin-right: var(--spacing);
		}
	}

	&.icon-only {
		--button-padding: calc(var(--spacing) * 2);
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;

		svg,
		i {
			--icon-size: 16px;
		}
	}

	&.link {
		--button-background-color: none;
		--button-hover-background-color: none;
		--button-border-color: none;

		&:hover:not(.no-underline) {
			text-decoration: underline;
		}
	}

	&.loading {
		app-loading-spinner {
			--loading-spinner-display: flex;
			--loading-spinner-size: 16px;
		}
	}

	app-loading-spinner {
		--loading-spinner-display: none;

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		align-items: center;
		justify-content: center;
		background: var(--button-background-color);
	}
}

a {
	font-style: normal;
	text-decoration: none;
	font-size: var(--font-size-small);
	cursor: pointer;
	color: var(--anchor-text-color);
	position: relative;
	display: flex;
	align-items: center;
	font-family: var(--font-family-secondary);
	width: fit-content;

	&.secondary {
		--anchor-text-color: var(--text-secondary);
	}

	&:not(.inline-icon) {
		& > {
			svg,
			i {
				position: absolute;
				transform: translateX(calc(-100% - var(--spacing)));
			}
		}
	}

	&:hover:not(.no-underline) {
		text-decoration: underline;
	}
}

*[routerLink] {
	cursor: pointer;
}
