i,
svg,
.svg-inline--fa {
	--icon-size: 24px;
	height: var(--icon-size);
	width: var(--icon-size);

	&.xsmall {
		--icon-size: 12px;
	}

	&.small {
		--icon-size: 18px;
	}

	&.large {
		--icon-size: 30px;
	}

	&.xlarge {
		--icon-size: 42px;
	}

	&.xxlarge {
		--icon-size: 54px;
	}
}
